import { Explorers } from '@common/constants/currency/settings/explorers';
import { TokenNames } from '@common/constants/currency/settings/tokenNames';
import { PartialRecord } from '@common/types/partialRecord.types';

export enum Currencies {
	usdt = 'usdt',
	btc = 'btc',
	eth = 'eth',
	xrp = 'xrp',
	doge = 'doge',
	bnb = 'bnb',
	trx = 'trx',
	shib = 'shib',
	ltc = 'ltc',
	xlm = 'xlm',
	sushi = 'sushi',
	uni = 'uni',
	etc = 'etc',
	bch = 'bch',
	ada = 'ada',
	dot = 'dot',
	optim = 'optim',
	matic = 'matic',
	pepe = 'pepe',
	bonk = 'bonk',
	floki = 'floki',
	sol = 'sol',
}

export enum CurrenciesLayout {
	usd = 'usd',
	pol = 'pol',
	eur = 'eur',
}

export type CurrencyType = Currencies | CurrenciesLayout;
export type CurrencyNetworkType = keyof typeof Explorers;
export type CurrencyTokenType = keyof typeof TokenNames;

export type CurrencyTypeMap<T> = PartialRecord<CurrencyType, T>;
export type CurrencyNetworkTypeMap<T> = PartialRecord<CurrencyNetworkType, T>;
export type CurrencyTokenTypeMap<T> = PartialRecord<CurrencyTokenType, T>;
