import { TransNamespace } from '@legacyApp/client/modules/translation/TransNamespace';
import { SLOT_PAGE_PREFIX } from './SlotMenu.constants';

export const SLOTS_ID = SLOT_PAGE_PREFIX;

export const SLOTS_PAGE_ID = {
	INDEX: SLOTS_ID,
	PROVIDER: `${SLOTS_ID}_provider`,
	CATEGORY: `${SLOTS_ID}_category`,
	GROUP: `${SLOTS_ID}_group`,
	FAVORITE: `${SLOTS_ID}_favorites`,
	PLAY: `${SLOTS_ID}_play`,
	NEW_GAMES: `${SLOTS_ID}_new_games`,
	ALL_PROVIDERS: `${SLOTS_ID}_all_providers`,
	PLAY_EXTERNAL: `${SLOTS_ID}_play_external`,
} as const;

export const SLOTS_META = {
	[SLOTS_PAGE_ID.INDEX]: {
		title: 'Bitcoin Slots - Crypto Slot Games',
		namespace: TransNamespace.SLOTS,
	},
	[SLOTS_PAGE_ID.PROVIDER]: {
		title: 'Bitcoin {{section_name}} Games - Crypto {{section_name}} Games',
		namespace: TransNamespace.SLOTS,
	},
	[SLOTS_PAGE_ID.CATEGORY]: {
		title: 'Bitcoin {{section_name}} Games - Crypto {{section_name}} Games',
		namespace: TransNamespace.SLOTS,
	},
	[SLOTS_PAGE_ID.GROUP]: {
		title: 'Bitcoin {{section_name}} Games - Crypto {{section_name}} Games',
		namespace: TransNamespace.SLOTS,
	},
	[SLOTS_PAGE_ID.FAVORITE]: {
		title: 'Favorite Bitcoin Slot Games - Crypto Slot Games',
		namespace: TransNamespace.SLOTS,
	},
	[SLOTS_PAGE_ID.PLAY]: {
		title: '{{section_name}}',
		namespace: TransNamespace.SLOTS,
	},
	[SLOTS_PAGE_ID.PLAY_EXTERNAL]: {
		title: '{{section_name}}',
		namespace: TransNamespace.SLOTS,
	},
	[SLOTS_PAGE_ID.NEW_GAMES]: {
		title: 'New Bitcoin Slot Games - Crypto Slot New Games',
		namespace: TransNamespace.SLOTS,
	},
	[SLOTS_PAGE_ID.ALL_PROVIDERS]: {
		title: 'Bitcoin All Providers - Crypto All Providers',
		namespace: TransNamespace.SLOTS,
	},
};
