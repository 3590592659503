export interface Slot {
	game_id: number;
	image_url: string;
	operator_id: number;
	provider_id: number;
	rtp_value: number;
	slug: string;
	name: string;
	view_aspect_ratio: number;
	provider_name: string;
	provider_slug: string;
	amount_usd: number;
	tags: Array<string>;
}

export interface ProviderDTO {
	id: number;
	name: string;
	slug: string;
	new: boolean;
}

export interface SlotDTO {
	game_id: number;
	image_url: string;
	operator_id: number;
	provider_id: number;
	rtp_value: number;
	slug: string;
	name: string;
	view_aspect_ratio: string;
	provider_name: string;
	provider_slug: string;
	amount_usd: number;
	tags: Array<string>;
}

export interface GameDetailsDTO extends Slot {
	description: string;
	has_demo: boolean;
	category: SlotCategoryDTO;
	provider: ProviderDTO;
	categories: SlotCategoryDTO[];
	limit: number;
}

export const AllCategory: SlotCategoryDTO = {
	id: 0,
	slug: 'all',
	name: 'All Games',
	show_in_menu: false,
	created_at: new Date(0).toISOString(),
};

export interface SlotCategoryDTO {
	id: number;
	name: string;
	slug: string;
	show_in_menu: boolean;
	created_at: string;
}

export interface SlotGroupDTO {
	name: string;
	slug: string;
}

export interface SlotsSearchResult {
	id: string;
	categories: [];
	name: string;
	pageCode: string;
	pageMobile: string;
	provider: string;
	subProvider: string;
	system: string;
	type: string;
	rtp: string;
	image: string;
}

export enum GameMode {
	DEMO = 'demo',
	REAL = 'real',
}

export interface SlotResponse {
	count: number;
	list: SlotDTO[];
}

export interface SlotCategoriesResponse {
	categories: SlotCategoryDTO[];
}

export interface SlotSearchResponse {
	data: SlotDTO[];
}

export interface FetchError {
	error: string;
	status: number;
}

export type SlotSearchFetchData = SlotSearchResponse & FetchError;

export type SlotFetchData = SlotResponse & FetchError;
