import { NEXT_CONFIG } from '@common/constants/config/nextConfig';
import { itMayBeTrueTrustMe } from '@common/methods/itMayBeTrueTrustMe';

export const getIndexingProperty = (property?: boolean): boolean => {
	if (itMayBeTrueTrustMe(NEXT_CONFIG.DISABLE_INDEXING)) {
		return false;
	}
	if (!NEXT_CONFIG.PRODUCTION) {
		return true;
	}
	return !!property;
};
