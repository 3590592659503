import { NEXT_CONFIG } from '@common/constants/config/nextConfig';
import { TABLE_ID } from '@modules/transactions/constants/TableId';
import { availableGames } from '@modules/games/Game/constants/availableGames';
import { MODAL_ID } from '@common/constants/modal/ids.modal.constants';
import { DOMAIN } from '@server/constants/DOMAIN';
import { game_config } from './game_config';
import { hour, minute, second } from './modules/app/time';

export const FEATURES_TYPE = {
	SLOTS: 'slots',
	CHAT: 'chat',
	AFFILIATE_CAMPAIGN_USER: TABLE_ID.affiliateCampaignUsers,
	CHRISTMAS: 'christmas',
	ONLINE_UPDATE: 'onlineUpdate',
	DARK: 'dark',
	PENDING_TRANSACTIONS: 'pendingTransaction',
	REFRESH_USER_DATA: 'refreshUserData',
	LICENSE: 'license',
	MY_BETS: 'myBets',
	PLINKO: 'plinko',
	WELCOME_MODAL: MODAL_ID.WELCOME_MODAL,
};
type FeatureId = keyof typeof FEATURES_TYPE;
export type FeatureType = (typeof FEATURES_TYPE)[FeatureId];
export const config = {
	appName: 'WOLFBET',
	logo: 'WOLF.BET',
	domain: DOMAIN,
	documentationUrl: `https://${DOMAIN}/api/docs`,
	affiliateBannersUrl:
		'https://drive.google.com/drive/folders/1SC4nvkBD_L2yATebbYbf6E6jkGlnEqAk?usp=sharing',
	defaultGame: 'dice',
	availableGames,
	valueMaxPrecision: 10,
	chatWidth: 320,
	chatWidthDesktop: 370,
	websockets: true,
	logger: !!(NEXT_CONFIG.DEV || NEXT_CONFIG.TEST),
	// logger: false,
	decimals: {
		bet: 8,
		balance: 10,
		payout: 4,
		winChance: 2,
		betValue: 2,
	},
	remind2faCounter: 3,
	raceLeaderboard: 10,
	raceWinners: 30,
	transactionsPaginationLength: 25,
	chatStreamLength: 100,
	transactionsMaxMyBetsLength: 500,
	autobetStoppedFromApiDelay: 2 * second,
	transactionsClearTimeout: 5 * second,
	refreshUserDataTimeout: hour,
	refreshRaceTimeout: 20 * second,
	refreshPreparingRaceTimeout: 1.1 * minute,
	refreshAffiliateDataTimeout: 10 * second,
	refreshOnlineTimeout: 60 * second,
	raceStartDelay: minute,
	delayTransactionsUpdate: 0.3 * second,
	sentryStateUpdateInterval: 15 * second,
	alertTimeout: 5 * second,
	longAlertTimeout: 20 * second,
	unreadMessageTimeout: 5 * second,
	mentionedAlertTimeout: 10 * second,
	// dailyStreakModalCloseTimeout: second,
	lastRegisterTimeout: 15 * minute,
	userRaceHistoryFetchExpire: hour,
	userStatsFetchExpire: 10 * second,
	maintenanceModeTimeout: minute,
	versionCheckTimeout: 60 * minute,
	serverErrorConnectionTimeout: 5 * second,
	serverConnectionTimeout: 90 * second,
	captchaDelay: 2 * minute,
	defaultHotKeys: [
		{
			id: 'min_amount',
			symbol: 'Q',
			keyCode: 81,
			name: 'MIN bet amount',
		},
		{
			id: 'half_amount',
			symbol: 'W',
			keyCode: 87,
			name: '1/2 bet amount',
		},
		{
			id: 'double_amount',
			symbol: 'E',
			keyCode: 69,
			name: '2x bet amount',
		},
	],
	chartLimit: 250,
	chartBoostThreshold: 1,
	games: {
		...game_config,
		maxWinChance: 98, // isRequired
		flashbet: {
			minRolls: 10,
			maxRolls: 25000,
		},
		autobet: {
			maxRolls: 4000000000, // below max unsigned int
			expertModePercentCommandDecimals: 2,
			maxStrategiesCount: 20,
		},
	},
	chat: {
		rooms: [
			{
				name: 'english',
				id: 'english',
				symbol: 'en',
			},
			{
				name: 'pусский',
				id: 'russian',
				symbol: 'ru',
			},
			{
				name: 'português',
				id: 'portuguese',
				symbol: 'pt',
			},
			{
				name: 'español',
				id: 'spanish',
				symbol: 'es',
			},
			{
				name: 'filipino',
				id: 'filipino',
				symbol: 'ph',
			},
			{
				name: 'indonesia',
				id: 'indonesian',
				symbol: 'id',
			},
			// {
			// 	name: 'türk',
			// 	id: 'turkish',
			// 	symbol: 'tr',
			// },
			{
				name: 'admin',
				id: 'admin',
				symbol: 'admin',
				admin: true,
			},
		],
		clearRoomTimeout: 10000,
	},
	errors: {
		tooManyFailedLogins: 'Too many failed login attempts',
		maintenanceMode: 'maintenance mode',
		moduleMaintenanceMode: 'Maintenance mode',
		twoFactorAuth: 'Code is required.',
		sessionEnded: 'Unauthenticated.',
	},
	responseErrorsToSentry: [
		'Playing hilo failed. Try again or contact support.',
		// 'Hilo not started.',
		// 'Incorrect sub nonce value given.',
		// 'Incorrect multiplier given.',
		'You cannot claim now! Try again later',
		'Multiple accounts detected',
	],
	replaceErrorsToSentry: {
		'read error on connection to': {
			label: 'Connection error #{{code}}',
			options: { code: 1000 },
			namespace: 'validation',
		},
	},
	extendedAccessFunction: [] as FeatureType[],
	disableFeatures: {
		[FEATURES_TYPE.WELCOME_MODAL]: true,
		[FEATURES_TYPE.PLINKO]: false,
		// [FEATURES_TYPE.MY_BETS]: NEXT_CONFIG.DEV,
		// [FEATURES_TYPE.REFRESH_USER_DATA]: true,
		[FEATURES_TYPE.LICENSE]: true,
		// [FEATURES_TYPE.SLOTS]: true,
		// [FEATURES_TYPE.CHAT]: true,
		[FEATURES_TYPE.AFFILIATE_CAMPAIGN_USER]: true,
		[FEATURES_TYPE.CHRISTMAS]: true,
		// [FEATURES_TYPE.DARK]: true,
		// [FEATURES_TYPE.VIP_BONUS_PAGE]: true,

		[FEATURES_TYPE.ONLINE_UPDATE]: NEXT_CONFIG.DEV,
		[FEATURES_TYPE.PENDING_TRANSACTIONS]: NEXT_CONFIG.DEV,
	} as Record<FeatureType, boolean>,
};
