import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { useCallback, useEffect } from 'react';

import { parseImageSrc } from '@common/components/default/Image/Image';
import { useStateEffect } from '@common/hooks/useStateEffect';
import { config } from '@legacyApp/client/config';
import { useMeta } from '@legacyApp/hooks/seo/useMeta';
import { DEFAULT_NAMESPACE } from '@legacyApp/client/modules/translation/translate.constants';
import { TransNamespace } from '@legacyApp/client/modules/translation/TransNamespace';
import { languageConfig } from '@server/config/language.config';
import { getIndexingProperty } from '@common/components/default/Seo/methods/getIndexingProperty';
import { NextSeoProps } from 'next-seo/lib/types';
import { EnhancedNextComponentPageProps } from '@common/hoc/pageEnhancer';

const Seo = ({
	titleTemplate,
	title,
	description,
	props,
	titleProps,
	descriptionProps,
	pageParams,
	namespace = DEFAULT_NAMESPACE,
}: {
	titleTemplate?: string;
	title?: string;
	description?: string;
	props?: NextSeoProps;
	titleProps?: any;
	descriptionProps?: any;
	pageParams?: EnhancedNextComponentPageProps['params'];
	namespace?: TransNamespace;
}) => {
	const { asPath, locale } = useRouter();

	const path = useStateEffect(
		useCallback(() => {
			const [pathname] = asPath.split('?');
			return `https://${config.domain}${
				locale === languageConfig.default || pathname.indexOf(locale) > -1
					? ''
					: `/${locale}`
			}${pathname === '/' ? '' : pathname}`;
		}, [asPath, locale]),
	);

	const meta = useMeta({
		title,
		titleTemplate,
		description,
		titleProps,
		descriptionProps,
		namespace,
	});

	const {
		title: props_title,
		description: props_description,
		...restProps
	} = props || {};

	const meta_title = pageParams?.page_content?.meta_title;
	const meta_description = pageParams?.page_content?.meta_description;

	const title_ = meta_title || props_title || meta.title;
	const description_ =
		meta_description || props_description || meta.description;

	useEffect(() => {
		// @ts-expect-error invalid type
		if (process.browser && window.wolfMetaTItle !== title_) {
			// @ts-expect-error invalid type
			window.wolfMetaTItle = title_;
		}
	}, [title_]);

	return (
		<NextSeo
			canonical={path}
			openGraph={{
				title: title_,
				description: description_,
				site_name: title_,
				type: 'website',
				locale,
				url: path,
				images: [
					{
						url: parseImageSrc('/img/og/social-image.webp'),
						width: 1500,
						height: 500,
					},
				],
			}}
			twitter={{
				handle: '@wolfdotbet',
				site: '@wolfdotbet',
				cardType: 'summary_large_image',
			}}
			{...restProps}
			nofollow={getIndexingProperty(props?.nofollow)}
			noindex={getIndexingProperty(props?.noindex)}
			title={title_}
			description={description_}
			disableGooglebot
		/>
	);
};

export default Seo;
