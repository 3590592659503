import { generateUrl, ROUTE } from '@common/routes';
import { createSlug } from '@common/helpers/createSlug';
import { GenerateUrl } from '@common/routes/Routes';
import { apiServerConfig } from '@server/config/api.server.config';
import { GameMode, Slot } from '../store/slots.types';

export interface SlotGameListQuery {
	categoryId?: number | number[];
	provider?: number | number[];
	orderBy?: string;
	page?: number;
	count?: number;
	group?: string;
	gameSlugToOmit?: string;
	filterType?: string;
}

export const generateSlotsApiUrl: GenerateUrl = (name, options) => {
	return `${apiServerConfig.slotsUrl}${generateUrl(name, options)}`;
};

export const generatePostFavoriteGameUrl = () => {
	return generateSlotsApiUrl(ROUTE.apiSlotFavoriteGames);
};

export const generatePostFavoriteGamesAllUrl = () => {
	return generateSlotsApiUrl(ROUTE.apiSlotFavoriteGamesAll);
};

export const generateGetCategories = () => {
	return generateSlotsApiUrl(ROUTE.apiSlotCategories);
};

export const generateGetFavoriteGamesUrl = (uuid, query: SlotGameListQuery) => {
	const { count, page, ..._query } = query;
	const take = count;
	const prevPage = (page || 1) - 1;
	const skip = prevPage > 0 ? count * prevPage : 0;
	return generateSlotsApiUrl(ROUTE.apiSlotGetFavoriteGames, {
		uuid,
		take,
		skip,
		..._query,
	});
};

export const generateGetNewGamesUrl = (take?: number) => {
	return generateSlotsApiUrl(ROUTE.apiSlotGetNewGames, {
		...(take && { take }),
	});
};

export const generateGetIsFavoriteGameUrl = () => {
	return generateSlotsApiUrl(ROUTE.apiSlotIsFavoriteGames);
};

export const generateSlotGameInfoUrl = ({ gameSlug }) => {
	return generateSlotsApiUrl(ROUTE.apiSlotGameInfo, {
		gameSlug,
	});
};

export const generatePlaySlotGameUrl = ({
	type,
	gameId,
	operatorId,
}: {
	type: GameMode;
	gameId: number;
	operatorId: number;
}) => {
	return generateSlotsApiUrl(ROUTE.apiPlaySlotGame, {
		type,
		gameId,
		operatorId,
	});
};

export const generateSummarizeBetsUrl = (system, page) => {
	return generateSlotsApiUrl(ROUTE.apiSummarizeBets, {
		system,
		page,
	});
};

export const generateSlotGameListUrl = (query?: SlotGameListQuery) => {
	const { count, page, ..._query } = query;
	const take = count;
	const prevPage = (page || 1) - 1;
	const skip = prevPage > 0 ? count * prevPage : 0;

	return generateSlotsApiUrl(ROUTE.apiSlotGamesList, {
		..._query,
		take,
		skip,
	});
};

export const generateSearchUrl = (term: string) => {
	return generateSlotsApiUrl(ROUTE.apiSlotSearch, {
		term,
	});
};

export const generateSlotUrl = (slot: Slot) => {
	const { slug } = slot;
	return generateUrl(ROUTE.slotPage, { slug });
};

export const generateSlotsHomeUrl = (
	query?: Record<string, string | number>,
) => {
	return generateUrl(
		ROUTE.slotsHomePage,
		query
			? {
					...query,
			  }
			: undefined,
	);
};

export const generateSlotCategoryUrl = (category: string) => {
	return generateUrl(ROUTE.slotCategoryPage, { category });
};

export const generateSlotProviderUrl = (provider: string) => {
	return generateUrl(ROUTE.slotProviderPage, { provider });
};

export const generateFetchSlotProvidersUrl = (
	options: { take?: number; isAdmin?: boolean } = {},
) => {
	return generateSlotsApiUrl(ROUTE.apiSlotProviders, options);
};

export const generatePlaySlotMobilePageUrl = (slot: Slot, mode: GameMode) => {
	if (!slot?.slug) {
		return null;
	}
	return generateUrl(ROUTE.slotMobilePage, {
		id: slot?.game_id,
		slug: createSlug(slot?.slug),
		mode,
	});
};

export const getSlotId = (operatorId: number, gameId: number) => {
	return `${operatorId}_${gameId}`;
};
