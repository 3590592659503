import { NEXT_CONFIG } from '@common/constants/config/nextConfig';
import { isFunction } from '@common/methods/isFunction';
import { parseImageSrc } from '@common/components/default/Image/Image';
import { lsSet } from '@modules/localStorage/methods/lsSet';
import { lsGet } from '@modules/localStorage/methods/lsGet';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';
import { jsonToString } from '@common/methods/jsonToString';
import FetchService from '../app/fetchService';
import { scientificToDecimal } from '../../../methods/math/scientificToDecimal';
import { DEFAULT_NAMESPACE } from './translate.constants';

export const changeLanguage = (lang) => {
	console.log(lang);
	// if (i18n.language === lang) return;
	// i18n.changeLanguage(lang);
	// console.log('%changeLanguage', 'color: #07d607; font-size: 20px', {
	//   lang,
	//   inst: i18n,
	// },);
};

export class TranslationServiceClass {
	timeout = null;

	translateString(key, t = (value) => value, allow) {
		if (!key) {
			return '';
		}
		if (typeof key === 'string') {
			this.checkTranslationExistence(key);
			return t(key);
		}
		if (isFunction(key)) {
			this.checkTranslationExistence(key);
			return key(t);
		}
		return allow ? key : '(Invalid translation)';
	}

	interpolate(string, data) {
		if (!data) {
			return string || null;
		}
		const keys = Object.keys(data);
		if (!keys.length) {
			return string || null;
		}
		keys.forEach((key) => {
			string = string.split(`{{${key}}}`).join(data[key]);
		});
		return string || null;
	}

	checkTranslationExistence(
		key: string,
		namespace = DEFAULT_NAMESPACE,
		callbackAfterFetch?: () => void,
	) {
		if (key === 'Translate key added') {
			return;
		}
		const json = this.getTranslationObject(
			namespace,
			undefined,
			callbackAfterFetch,
		);
		// console.log('checkTranslationExistence', { json: json?.[key], key, namespace });
		if (json?.[key] || (!json && namespace === DEFAULT_NAMESPACE)) {
			return;
		}
		// if (!json) {
		// 	console.error(`Missing translations Namespace - ${namespace}`, key);
		// }
		// if (json && !json?.[key]) console.log('checkTranslationExistence', {key, x: json?.[key], namespace});
		if (!NEXT_CONFIG.DEV) {
			return;
		}
		// if (key === 'Sort by') {
		// console.log('checkTranslationExistence', {
		// 	key,
		// 	namespace,
		// 	jsonKey: json?.[key],
		// 	json,
		// });
		// }
		if (key) {
			this.addMissingTranslation(key, namespace);
		}
	}

	addMissingTranslation(key, namespace = DEFAULT_NAMESPACE) {
		if (!NEXT_CONFIG.DEV) {
			return;
		}
		const data = lsGet(LocalStorageKeys.MISSING_TRANSLATIONS) || [];
		// if (key === 'Sort by') console.log('addMissingTranslation', {
		// 	key,
		// 	data,
		// });
		if (typeof key === 'object') {
			if (!key?.props?.translateKey) {
				return;
			}
			key = key.props.translateKey;
		}
		key = `${namespace}:${key}`;
		if (data.indexOf(key) === -1) {
			// console.log('Key not exist: ', key);
			data.push(key);
			lsSet(LocalStorageKeys.MISSING_TRANSLATIONS, data);
		}
		this.updateMissingTranslations();
	}

	updateMissingTranslations() {
		if (!NEXT_CONFIG.DEV) {
			return;
		}
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		this.timeout = setTimeout(() => {
			const data = lsGet(LocalStorageKeys.MISSING_TRANSLATIONS);
			if (!data || !data.length) {
				return;
			}
			return FetchService.fetch(
				'/api/check_translation',
				FetchService.parseParameters({
					body: jsonToString({
						data,
					}),
					method: 'POST',
				}),
			).then((response) => {
				if (response.error) {
					return;
				}
				const array = lsGet(LocalStorageKeys.MISSING_TRANSLATIONS);
				if (!array || !array.length) {
					return;
				}
				lsSet(
					LocalStorageKeys.MISSING_TRANSLATIONS,
					array.filter((el) => data.indexOf(el) === -1),
				);
			});
		}, 5000);
	}

	getTranslationObject(
		namespace = DEFAULT_NAMESPACE,
		lang = 'en',
		callbackAfterFetch = null,
	) {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const { i18n } = require('next-i18next');
		if (!i18n) {
			return null;
		}
		const language = i18n.language || lang;
		const resourceBundle = i18n.getResourceBundle(language, namespace);
		if (!resourceBundle) {
			const url = parseImageSrc(
				`/translations/${language}/${namespace}.json?v=${NEXT_CONFIG.RELEASE_VERSION}`,
			);
			const isPending = FetchService.isPending(url);
			if (!isPending) {
				// console.log('getTranslationObject', { namespace, data: i18n?.store?.data, language: i18n?.language, lang });
				// console.log(i18n);
				FetchService.fetch(url, { method: 'GET' }, true).then((response) => {
					FetchService.getResponseData(response).then((data) => {
						// console.log('resourceBundle', { language, namespace, isPending, url }, data);
						i18n.addResourceBundle(language, namespace, data, true, true);
						i18n.reloadResources(language, namespace);
						if (isFunction(callbackAfterFetch)) {
							callbackAfterFetch();
						}
					});
				});
			}
		}
		return resourceBundle;
	}
}

export const mapTransData = (data: any) => {
	if (!data) {
		return data;
	}

	Object.keys(data).forEach((key) => {
		if (typeof data[key] === 'number') {
			data[key] = scientificToDecimal(data[key]);
		}
		if (data[key] === 'usdt') {
			data[key] = 'usd';
		}
		if (data[key] === 'USDT') {
			data[key] = 'USD';
		}
	});

	return data;
};

export const translationService = new TranslationServiceClass();

export const simpleTrans = (
	string: string,
	data?: any,
	namespace = DEFAULT_NAMESPACE,
) => {
	if (!data) {
		translationService.checkTranslationExistence(string, namespace);
	}
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const { i18n } = require('next-i18next');
	// console.log('simpleTrans', { string, data, namespace, language: i18n?.language, t: i18n.t(`${namespace}:${string}`, data) });
	if (!isFunction(i18n?.getFixedT)) {
		return translationService.interpolate(string, mapTransData(data));
	}
	const t = i18n.getFixedT(null, namespace);
	// console.log('simpleTrans - result', i18n.t(`${namespace}:${string}`, data), {string: `${namespace}:${string}`, data});
	return t(string, mapTransData(data));
};
